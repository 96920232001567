<template>
  <div class="locale-changer">
    <b-input-group size="sm">
      <b-input-group-prepend is-text>
        <fa-icon
          icon="globe"
          class="text-ipxx-medium"
        />
      </b-input-group-prepend>
      <b-form-select v-model="locale">
        <option
          v-for="(lang, i) in languages"
          :key="i"
          :value="lang"
        >
          {{ language[lang] }}
        </option>
      </b-form-select>
    </b-input-group>
  </div>
</template>

<script>

export default {
  name: 'LocaleChanger',
  data() {
    return {
      language: {
        de: 'Deutsch',
        en: 'English',
      },
      languages: Object.keys(this.$i18n.messages),
      locale: this.$i18n.locale,
    };
  },
  watch: {
    locale(newLocale) {
      this.$root.$i18n.locale = newLocale;
      this.$http.defaults.headers.common['Accept-Language'] = newLocale;
    },
  },
};
</script>
