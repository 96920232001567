<!-- eslint-disable max-len -->
<i18n>
  {
    "de": {
      "button_more": "Mehr von uns",
      "intro_text": "Die Software für Ihre Bildersuche auf einem einmaligen Marktplatz aus Stockbildagenturen, Pressebildagenturen, Archiven und Portalen.",
      "intro_text_short": "Die Software für Ihre Bildersuche.",
      "link_homepage": "https://www.picturemaxx.com/my-picturemaxx",
      "title": "my-picturemaxx - die Nummer 1 im Bildermarkt",
      "title_short": "my-picturemaxx"
    },
    "en": {
      "button_more": "More about us",
      "intro_text": "The my-picturemaxx search engine allows you to search simultaneously across hundreds of press agencies, stock agencies and more for finding and purchasing visual content.",
      "intro_text_short": "The software for searching visual content.",
      "link_homepage": "https://www.picturemaxx.com/my-picturemaxx?lang=en",
      "title": "my-picturemaxx - the #1 in the Marketplace",
      "title_short": "my-picturemaxx"
    }
  }
</i18n>
<!-- eslint-enable max-len -->

<template>
  <div class="ipxx-mypm-info">
    <p
      v-if="small"
      class="font-weight-bold"
    >
      {{ $t('title_short') }}
    </p>
    <h4 v-else>
      {{ $t('title') }}
    </h4>
    <p>
      {{ small ? $t('intro_text_short') : $t('intro_text') }}
    </p>
    <b-button
      size="sm"
      :href="$t('link_homepage')"
      target="_blank"
    >
      {{ $t('button_more') }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'MypmInfo',
  props: {
    small: Boolean,
  },
};
</script>
