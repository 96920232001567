<!-- eslint-disable max-len -->
<i18n>
  {
    "de": {
      "column_company": "Unternehmen",
      "column_product": "Produkt News",
      "column_support": "Support / Help Center",
      "company_contact": "Kontakt",
      "company_privacy_policy": "Datenschutz",
      "company_terms_conditions": "AGB",
      "link_contact": "https://www.picturemaxx.com/contacts",
      "link_facebook": "https://www.facebook.com/picturemaxx.ag",
      "link_instagram": "https://www.instagram.com/_picturemaxx_/",
      "link_linkend_in": "https://www.linkedin.com/company/picturemaxx-ag",
      "link_newsletter": "https://www.picturemaxx.com/newsletter-picturemaxx",
      "link_privacy_policy": "https://www.picturemaxx.com/privacy-policy",
      "link_terms": "https://www.picturemaxx.com/terms",
      "link_twitter": "https://twitter.com/picturemaxx",
      "link_tutorials": "https://www.picturemaxx.com/tutorials",
      "link_whats_new": "https://www.picturemaxx.com/my-picturemaxx-whats-new",
      "product_newsletter": "Newsletter",
      "product_tutorials": "Tutorials",
      "product_whats_new": "What's new",
      "support_phone": "+49 89 357 147-0"
    },
    "en": {
      "column_company": "Company",
      "column_product": "Product News",
      "column_support": "Support / Help Center",
      "company_contact": "Contact",
      "company_privacy_policy": "Privacy Policy",
      "company_terms_conditions": "Terms & Conditions",
      "link_contact": "https://www.picturemaxx.com/contacts?lang=en",
      "link_facebook": "https://www.facebook.com/picturemaxx.ag",
      "link_instagram": "https://www.instagram.com/_picturemaxx_/",
      "link_linkend_in": "https://www.linkedin.com/company/picturemaxx-ag",
      "link_newsletter": "https://www.picturemaxx.com/newsletter-picturemaxx?lang=en",
      "link_privacy_policy": "https://www.picturemaxx.com/privacy-policy?lang=en",
      "link_terms": "https://www.picturemaxx.com/terms?lang=en",
      "link_twitter": "https://twitter.com/picturemaxx",
      "link_tutorials": "https://www.picturemaxx.com/tutorials?lang=en",
      "link_whats_new": "https://www.picturemaxx.com/my-picturemaxx-whats-new?lang=en",
      "product_newsletter": "Newsletter",
      "product_tutorials": "Tutorials",
      "product_whats_new": "What's new",
      "support_phone": "+49 89 357 147-0"
    }
  }
</i18n>
<!-- eslint-enable max-len -->

<template>
  <div class="ipxx-footer">
    <div class="ipxx-mypm-info text-left mb-5 d-none d-sm-block">
      <b-row class="justify-content-center no-gutters">
        <b-col
          sm="10"
          md="9"
          cols="10"
          class="ipxx-footer-text-padding"
        >
          <ipxx-mypm-info :small="false" />
        </b-col>
      </b-row>
    </div>
    <b-card
      class="border-0"
      no-body
    >
      <b-row class="justify-content-center no-gutters">
        <b-col
          sm="3"
          cols="8"
          class="d-block d-sm-none"
        >
          <b-card-body
            body-bg-variant="white"
            body-class="text-left"
          >
            <b-card-text>
              <ipxx-mypm-info :small="true" />
            </b-card-text>
          </b-card-body>
        </b-col>
        <b-col
          sm="3"
          cols="4"
        >
          <b-card-body
            body-bg-variant="default"
            body-class="text-left text-nowrap"
          >
            <b-card-text class="ipxx-hovered-link">
              <p class="font-weight-bold">
                {{ $t('column_product') }}
              </p>
              <div class="my-3">
                <a
                  :href="$t('link_whats_new')"
                  target="_blank"
                >
                  {{ $t('product_whats_new') }}
                </a>
              </div>
              <div class="my-3">
                <a
                  :href="$t('link_newsletter')"
                  target="_blank"
                >
                  {{ $t('product_newsletter') }}
                </a>
              </div>
            </b-card-text>
          </b-card-body>
        </b-col>
        <b-col
          sm="3"
          cols="5"
        >
          <b-card-body
            body-bg-variant="default"
            body-class="text-left"
          >
            <b-card-text class="ipxx-hovered-link">
              <p class="font-weight-bold text-nowrap">
                {{ $t('column_company') }}
              </p>
              <div class="my-3 text-nowrap">
                <a
                  :href="$t('link_contact')"
                  target="_blank"
                >
                  {{ $t('company_contact') }}
                </a>
              </div>
              <div class="my-3">
                <a
                  :href="$t('link_terms')"
                  target="_blank"
                >
                  {{ $t('company_terms_conditions') }}
                </a>
              </div>
              <div class="my-3 text-nowrap">
                <a
                  :href="$t('link_privacy_policy')"
                  target="_blank"
                >
                  {{ $t('company_privacy_policy') }}
                </a>
              </div>
            </b-card-text>
          </b-card-body>
        </b-col>
        <b-col
          sm="4"
          md="3"
          cols="7"
        >
          <b-card-body
            body-bg-variant="default"
            body-class="text-left text-nowrap"
          >
            <b-card-text class="ipxx-hovered-link">
              <p class="font-weight-bold">
                {{ $t('column_support') }}
              </p>
              <div class="my-3">
                <a
                  :href="$t('link_tutorials')"
                  target="_blank"
                >
                  {{ $t('product_tutorials') }}
                </a>
              </div>
              <div class="my-3">
                <fa-icon
                  class="text-ipxx-medium mr-1"
                  :icon="['fa', 'envelope']"
                />
                {{ $t('corporate.support_email') }}
              </div>
              <div class="my-3">
                <fa-icon
                  class="text-ipxx-medium mr-1"
                  :icon="['fa', 'phone']"
                />
                {{ $t('support_phone') }}
              </div>
            </b-card-text>
          </b-card-body>
        </b-col>
      </b-row>
      <b-card-footer footer-bg-variant="ipxx-lightest">
        <b-row class="justify-content-center">
          <div class="mr-4">
            <a
              class="btn-link"
              :href="$t('link_instagram')"
              target="_blank"
            >
              <ipxx-brand icon-name="instagram" />
            </a>
          </div>
          <div class="mr-4">
            <a
              class="btn-link"
              :href="$t('link_linkend_in')"
              target="_blank"
            >
              <ipxx-brand icon-name="linkedin-in" />
            </a>
          </div>
          <div class="mr-4">
            <a
              class="btn-link"
              :href="$t('link_facebook')"
              target="_blank"
            >
              <fa-icon
                class="py-1 text-ipxx-medium"
                :icon="['fab', 'facebook']"
                transform="grow-40"
              />
            </a>
          </div>
          <div class="mr-4">
            <a
              class="btn-link"
              :href="$t('link_twitter')"
              target="_blank"
            >
              <ipxx-brand icon-name="twitter" />
            </a>
          </div>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import IpxxMypmInfo from '@/components/MypmInfo';

export default {
  name: 'MypmFooter',
  components: {
    IpxxMypmInfo,
  },
};
</script>

<style lang="scss">
.ipxx-mypm-info {
  background-color: white;
  .ipxx-footer-text-padding {
    padding: ($spacer * 1.25);
  }
}
</style>
