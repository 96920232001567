<i18n>
  {
    "de": {
      "imprint": "Impressum",
      "link_imprint": "https://www.picturemaxx.com/imprint"
    },
    "en": {
      "imprint": "Imprint",
      "link_imprint": "https://www.picturemaxx.com/imprint?lang=en"
    }
  }
</i18n>

<template>
  <div class="ipxx-mypm-header bg-white">
    <b-row class="justify-content-end m-0">
      <b-col class="col-2 col-md-5">
        <b-img
          class="d-block d-sm-none ipxx-header-icon"
          :src="`${publicPath}assets/icon-my-pm.svg`"
          alt="my-picturemaxx icon"
        />
        <b-img
          class="d-none d-sm-block ipxx-header-icon"
          :src="`${publicPath}assets/icon-my-pm-logo.svg`"
          alt="my-picturemaxx icon"
        />
      </b-col>
      <b-col class="col-10 col-md-7">
        <div class="pt-4 ipxx-hovered-link">
          <ipxx-locale-changer class="float-right mr-4" />
          <a
            :href="$t('link_imprint')"
            target="_blank"
            class="float-right pt-1 mr-4"
          >
            {{ $t('imprint') }}
          </a>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import IpxxLocaleChanger from '@/components/LocaleChanger';

export default {
  name: 'MypmHeader',
  components: {
    IpxxLocaleChanger,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss">
.ipxx-mypm-header {
  min-height: $mypm-icon-height;
}
</style>
