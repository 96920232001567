import { helpers } from 'vuelidate/lib/validators';

/**
 * Validate the input to be a valid and strong password.
 */
const strongPassword = helpers.withParams({
  type: 'strongPassword',
}, (value) => {
  if (!helpers.req(value)) {
    return false;
  }
  return (
    /[A-Z]/.test(value) // checks for A-Z
    && /[a-z]/.test(value) // checks for a-z
    && /[0-9]/.test(value) // checks for 0-9
    && /[^0-9a-zA-Z]/.test(value) // checks for special chars
    && value.length >= 12
  );
});

export default strongPassword;
