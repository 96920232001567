<!-- eslint-disable max-len -->
<i18n>
  {
    "de": {
      "error_password_invalid": "Das eingegebene Passwort ist nicht gültig. Bitte geben Sie ein anderes Passwort ein.",
      "intro": "Bitte geben Sie hier Ihr neues Passwort ein.",
      "password": "Passwort",
      "password_feedback" : "Mindestlänge 12 Zeichen, davon mindestens: 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Ziffer, 1 Sonderzeichen. Darf nicht mit einem Leerzeichen beginnen oder enden.",
      "password_repeat": "Passwort wiederholen",
      "password_repeat_feedback" : "Die Passwörter stimmen nicht überein.",
      "submit_button_text": "Passwort ändern",
      "success_message": "Ihr Passwort wurde erfolgreich geändert. Bitte wechseln Sie jetzt wieder in die my-picturemaxx Anwendung und setzen Sie den Login dort fort."
    },
    "en": {
      "error_password_invalid": "The entered password is not valid. Please enter a different password.",
      "intro": "Please enter your new password here.",
      "password": "Password",
      "password_feedback" : "Enter at least 12 characters, including at least 1 capital letter, 1 lowercase letter, 1 digit, 1 special char. May not begin or end with a whitespace.",
      "password_repeat": "Password repeat",
      "password_repeat_feedback": "The passwords do not match.",
      "submit_button_text": "Change Password",
      "success_message": "Your password has been successfully changed. Please switch back to the my-picturemaxx application and continue with the login."
    }
  }
</i18n>
<!-- eslint-enable max-len -->

<template>
  <div class="ipxx-reset-password ipxx-background-image">
    <ipxx-mypm-header />
    <div class="ipxx-reset-password-content flex-grow-1 mx-4 pt-5">
      <h1>{{ $t('titles.reset_password') }}</h1>
      <b-row class="justify-content-center">
        <div class="ipxx-form-responsive-col">
          {{ $t('intro') }}
        </div>
      </b-row>
      <b-row class="justify-content-center">
        <div class="ipxx-form-responsive-col mt-3">
          <b-alert
            :show="showMessage"
            :variant="messageType"
            data-cy="messageResetPassword"
            v-html="messageText"
          />
        </div>
      </b-row>
      <b-form
        v-if="!showMessage || errorCode"
        class="text-left my-4"
        @submit.prevent="resetPassword"
      >
        <b-row class="justify-content-center">
          <div class="ipxx-form-responsive-col">
            <b-form-group
              label-for="inputPassword"
              :label="`${$t('password')}*`"
              :invalid-feedback="$t('password_feedback')"
              :state="passwordState"
              data-cy="passwordFormGroup"
            >
              <b-form-input
                id="inputPassword"
                v-model="$v.password.$model"
                autocomplete="password"
                :state="passwordState"
                tabindex="1"
                trim
                type="password"
              />
            </b-form-group>
          </div>
        </b-row>
        <b-row class="justify-content-center">
          <div class="ipxx-form-responsive-col">
            <b-form-group
              label-for="inputPasswordRepeat"
              :label="`${$t('password_repeat')}*`"
              :invalid-feedback="$t('password_repeat_feedback')"
              :state="passwordRepeatState"
              data-cy="passwordRepeatFormGroup"
            >
              <b-form-input
                id="inputPasswordRepeat"
                v-model="$v.passwordRepeat.$model"
                autocomplete="off"
                :state="passwordRepeatState"
                tabindex="2"
                trim
                type="password"
              />
            </b-form-group>
          </div>
        </b-row>
        <b-row class="justify-content-center">
          <div class="ipxx-form-responsive-col">
            <b-btn
              type="submit"
              variant="primary"
              class="float-right"
              data-cy="changePasswordSubmitBtn"
              :disabled="$v.validationGroup.$invalid"
              tabindex="3"
            >
              {{ $t('submit_button_text') }}
            </b-btn>
          </div>
        </b-row>
      </b-form>
    </div>
    <ipxx-mypm-footer />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';
import IpxxMypmHeader from '@/components/MypmHeader';
import IpxxMypmFooter from '@/components/MypmFooter';
import strongPassword from '../validators/strongPassword';

export default {
  name: 'ResetPassword',
  components: {
    IpxxMypmFooter,
    IpxxMypmHeader,
  },
  mixins: [validationMixin],
  data() {
    return {
      errorCode: null,
      password: '',
      passwordRepeat: '',
      showMessage: false,
    };
  },
  computed: {
    messageText() {
      if (this.errorCode) {
        if (this.errorCode === this.$config.errors.account_reset_password_invalid) {
          return this.$t('error_password_invalid');
        }
        if (this.errorCode === this.$config.errors.account_reset_password_already_auth_assigned) {
          return this.$t('errors.error_already_auth_assigned', {
            account: this.$t('corporate.mypm_account'),
            providerAccount: this.$t('providers.default_account'),
          });
        }
        return this.$t('errors.unknown_error');
      }
      return this.$t('success_message');
    },
    messageType() {
      if (this.errorCode) {
        return 'danger';
      }
      return 'success';
    },
    passwordState() {
      if (!this.$v.password.$dirty) {
        return null;
      }
      return !this.$v.password.$invalid;
    },
    passwordRepeatState() {
      if (!this.$v.passwordRepeat.$dirty) {
        return null;
      }
      return !this.$v.passwordRepeat.$invalid;
    },
  },
  methods: {
    resetPassword() {
      this.errorCode = null;
      const postData = {
        password: this.password,
      };
      const { token } = this.$route.query;
      this.$store.commit('setAuthToken', token);
      this.$http.post(this.$routes.password_reset, postData)
        .catch((error) => {
          if (error.data && error.data.failureId) {
            this.errorCode = error.data.failureId;
          } else {
            this.errorCode = this.$config.errors.internal_server_error;
          }
        })
        .finally(() => {
          this.password = '';
          this.passwordRepeat = '';
          this.$v.validationGroup.$reset();
          this.showMessage = true;
        });
    },
  },
  validations: {
    password: {
      required,
      strongPassword,
    },
    passwordRepeat: {
      sameAsPassword: sameAs('password'),
    },
    validationGroup: ['password', 'passwordRepeat'],
  },
};
</script>
